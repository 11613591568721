export default class {
  execute(properties, component) {
    // See https://github.com/trello/power-up-template/blob/gh-pages/auth-success.html
    const token = window.location.hash.substring(7);

    if (this.constructor.tokenLooksValid(token)) {
      this.constructor.sendTokenToPowerUp(token)
    }

    this.constructor.endAuthFlow()
  }

  static tokenLooksValid(token) {
    // This is a workaround to prevent storing an invalid token, which is caused by Trello
    // giving us a similar looking URL regardless of whether the user has allowed or denied
    // the authorization.
    return /^[0-9a-fA-Z]*$/.test(token);
  }

  // Send the resulting Oauth token back to the Power-Up.
  // See: https://developer.atlassian.com/cloud/trello/power-ups/client-library/t-authorize/
  static sendTokenToPowerUp(token) {
    let authorize;

    try {
      if (window.opener && typeof window.opener.authorize === "function") {
        authorize = window.opener.authorize;
      }
    } catch (e) {
      // Security settings are preventing this, fall back to local storage.
    }

    if (authorize) {
      authorize(token);
    } else {
      localStorage.setItem("token", token);
    }
  }

  static endAuthFlow() {
    setTimeout(() => {
      window.close();
    }, 1000);
  }
}
